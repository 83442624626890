<template>
  <div class="page-content-main pd-10">
    <el-button size="small" icon="el-icon-back" @click="$router.push({path:'/system/group'})">返回列表</el-button>
    <div class="dark-2 pd-t-15">编辑 <b class="green-0 f-b">{{ formData.name }}</b> 的权限</div>
    <div class="line"></div>
    <el-tree
      :data="dataList"
      show-checkbox
      node-key="id"
      default-expand-all
      :expand-on-click-node="false"
      check-on-click-node
      :props="defaultProps"
      :default-checked-keys="treeChecked"
      @check-change="checkChange" ref="tree">
    </el-tree>
    <div class="padding-top-bottom">
      <el-button type="primary" @click="submitForm">保存</el-button>
      <el-button type="" @click="$router.back()">返回</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "MemberList",
  data() {
    return {
      dataList: [],
      formData: {
        id: '',
        rule_ids: [],
        all_rule_ids:[],
        name: ''
      },
      treeChecked: [],
      defaultProps: {
        children: 'children',
        label: 'title'
      }
    }
  },
  created() {
    this.formData.id = this.$route.query.id ? this.$route.query.id : 0;
  },
  methods: {
    getList() {
      let _this = this;
      this.dataList = [];

      this.$http.request({
        url: '/SystemRole/listTree',
	      datas:{menu_type:['admin','find_in_set'],orderBy:'sort',sortBy:'asc'},
        success(res) {
          _this.dataList = res;
        }
      })
    },
    del(id) {
      let _this = this;
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _this.$api.ruleGroupDel({id: id}, function (res) {
          _this.getList();
        })
      }).catch(() => {
      });
    },
    getDetail() {
      let _this = this;

      this.$http.request({
        url:'/SystemGroup/detail',
        datas:{id:this.formData.id},
        success(res){
          _this.formData.id = res.id;
          _this.formData.name = res.name;
          _this.formData.rule_ids = res.rule_ids;
          _this.formData.all_rule_ids = res.all_rule_ids;

          if (_this.formData.rule_ids) {
            _this.treeChecked = _this.formData.rule_ids.split(",");
          }
        }
      })
    },
    checkChange() {
      let _leaf_ids = this.$refs.tree.getCheckedKeys(true);
      let _all_leaf_ids = [];

      let _tmp_all = this.$refs.tree.getCheckedNodes(false,true);

      for(let i=0;i < _tmp_all.length;i++){
        _all_leaf_ids.push(_tmp_all[i].id);
      }

      let _rule_ids = [];
      let _all_rule_ids = [];

      if (_leaf_ids.length > 0) {

        _rule_ids = this.$utils.removal(_leaf_ids);
        _all_rule_ids = this.$utils.removal(_all_leaf_ids);

        _rule_ids.sort(function sortNumber(a, b) {
          return a - b
        });

        _all_rule_ids.sort(function sortNumber(a, b) {
          return a - b
        });

        this.formData.rule_ids = _rule_ids.join(",");
        this.formData.all_rule_ids = _all_rule_ids.join(",");
      } else {
        this.formData.rule_ids = '';
        this.formData.all_rule_ids = '';
      }
    },
    submitForm() {
      let _this = this;
      this.$http.request({
        url:'/SystemGroup/edit',
        datas:this.formData,
        success(res){
          console.log(res);
          _this.$message.success('保存成功');

          _this.$user.removeTab();
        }
      })
    }
  },
  mounted() {
    this.getList();

    if (this.formData.id !== 0) {
      this.getDetail();
    }
  }
}
</script>
<style>
.el-tree > .el-tree-node {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.el-tree > .el-tree-node > .el-tree-node__children > .el-tree-node > .el-tree-node__children {
  flex-wrap: wrap;
  display: flex !important;
}

/*.el-tree .el-tree-node__children{display: flex !important;}*/
</style>
